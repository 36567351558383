const data = {
    // 班主任学科id
    HEAD_TEACHER_SUBJECT_ID:C('headTeacher') || 1,
    // 性别枚举
    SEX_ENUM:{
        // 设置
        'MALE':1,
        'FEMALE':0,
        // 列表返回处理
        0: '女',
        1: '男'
    },
    // 年级枚举
    GRADE_ENUM:{
        1: '一年级',
        2: '二年级',
        3: '三年级',
        4: '四年级',
        5: '五年级',
        6: '六年级',
    },
    GRADE_LIST:[
        {name:'一年级',value:1},
        {name:'二年级',value:2},
        {name:'三年级',value:3},
        {name:'四年级',value:4},
        {name:'五年级',value:5},
        {name:'六年级',value:6},
    ],
    // 账号状态
    STATUS_ENUM:{
        0: '禁用',
        1: '正常',
    },
    // 状态
    STATUS_NAME_ENUM:{
        0:'否',
        1:'是',
    },
    // 评语库类型
    COMMENT_TYPE:{
        SYSTEM:'system',
        CUSTOMER:'customer'
    }
}
export default data
