<!--
 * ======================================
 * 说明： 年级组长
 * 作者： Silence
 * 文件： gradeteacher.vue
 * 日期： 2023/8/2 0:08
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <Scrollbar>
      <Table :options="handleOptions" :autoLoading="true"  :loadData="handleLoadData" title="年级组长" tableName="年级组长" ref="table">
        <template #search>
          <el-form :inline="true" :model="formSearch" class="demo-form-inline">
            <el-form-item label="学期" prop="semesterId">
              <el-select clearable class="w-full" v-model="formSearch.semesterId">
                <el-option v-for="item in semesterOptions" :key="item.value" :label="item.semester_name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年级">
              <el-select clearable class="w-full" v-model="formSearch.grade">
                <el-option v-for="(grade) in DATA.GRADE_LIST" :key="'grade' + grade.value" :label="grade.name" :value="grade.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleLoad">查询</el-button>
              <el-button @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #action>
          <div>
            <el-button type="primary" @click="handleCreate" icon="el-icon-plus">添加年级组长</el-button>
            <el-button type="primary" @click="handlePermissions" >权限设置</el-button>
            <!--          <el-button type="primary" @click="handleCreate" icon="el-icon-plus">发布劳动任务</el-button>-->
          </div>

        </template>
        <template #handle="{index,row}">
          <el-button type="text" icon="el-icon-edit" @click="handleLogin(row)">登录</el-button>
          <el-button type="text" @click="handleEdit(row)" icon="el-icon-edit">修改</el-button>
          <el-button type="text" @click="handleDelete(row)" icon="el-icon-delete">删除</el-button>
        </template>
        <template #info v-if="false">
          <ul class="flex flex-grow mb-2">
            <li class=" flex items-center justify-center"> <span class="h-4 inline-flex border-l-4 border-blue-700 mr-1"></span>应考人数：11</li>
            <li class=" flex items-center justify-center"> <span class="h-4 inline-flex border-l-4 border-blue-700 mr-1 ml-6"></span>学科班级平均分：11</li>
            <li class=" flex items-center justify-center"> <span class="h-4 inline-flex border-l-4 border-blue-700 mr-1 ml-6"></span>学科年级平均分：11</li>
            <li class=" flex items-center justify-center"> <span class="h-4 inline-flex border-l-4 border-blue-700 mr-1 ml-6"></span>任课老师：11</li>
          </ul>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script>
import edit from './components/gradeteacherEdit.vue'
import DATA from '@/common/data'
import Cookies from "js-cookie";
import Permissions from './components/permissions.vue'
export default {
  name: 'gradeteacher',
  data() {
    return {
      DATA,
      semesterOptions:[],
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        grade:'',
        semesterId:'',
      },
    }
  },
  mounted() {
    this.handleLoadSemesterOptions();
  },
  methods: {
    handleLoadSemesterOptions(){
      this.$cloud.get("semester/list",{
        size:99999,
        page:1,
      }).then(res => {
        this.semesterOptions = res.children;
      })
    },

    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/teaching/gradeteacher").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}) {
      //获取分页数据结构
      this.$cloud.get("grade/list",{size, page,...this.formSearch} ).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    
    //权限设置
    handlePermissions(){
        this.$cloud.dialog({
          title: '权限设置',
          data: {
            id:this.C('gradeLeader')
          },
          component: Permissions,
          success: '添加成功!',
          warning: '添加失败!',
          refresh: this.handleRefresh
        })
    },
    
    handleCreate() {
      this.$cloud.dialog({
        title: '添加年级组长',
        data: {},
        component: edit,
        success: '添加成功！',
        warning: '添加失败！',
        refresh: this.handleRefresh
      })
    },
    handleEdit(item) {
      this.$cloud.dialog({
        title: '更新年级组长',
        data: item,
        component: edit,
        success: '更新成功！',
        warning: '更新失败！',
        refresh: this.handleRefresh
      })
    },
    handleDelete(item) {
      console.log("删除",item)
      this.$confirm('此操作将永久删除该年级组长, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("grade/remove", {id: item.id}).then(() => {
          this.$message.success('删除成功！')
          this.handleRefresh()
        })
      })
    },
    handleLoad() {
      this.$refs['table'].handleLoadData()
    },
    handleReset() {
      this.formSearch = {
        grade:'',
        semesterId:'',
      }
      this.$refs['table'].handleLoadData()
    },
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    },
    // 一键登录
    handleLogin(row){
      console.log("登录",row)
      let teacherId = row.teacher_id
      this.$message.info("登录中")
      this.$cloud.post("teacher/fast/login", {teacherId}).then(res=>{
        console.log('res',res)
        this.$message.success("登录成功")
        Cookies.remove("hankun_cloud_template_token");
        Cookies.set("hankun_cloud_template_token",res.token)
        window.location.replace('/pages/web/panel')
        // this.$cloud.go("/pages/web/panel");
      })
    }
  },
}
</script>
<style lang="scss" scoped></style>
